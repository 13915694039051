import { graphql } from 'gatsby'
import Moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import Helmet from 'react-helmet'
import { EmailShareButton, FacebookShareButton, LinkedinShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share'
import { Layout, PostCardSide } from '../components/common'
import { MetaData } from '../components/common/meta'
import defaultImage from '../images/user-image.png'
import '../styles/custom.css'
import '../styles/post.css'
import '../styles/post_responsive.css'
import '../styles/readmore.css'
Moment.locale(`es`)





/**
* Single post view (/:slug)
*
* This file renders a single post and loads all the content.
*
*/
const Post = ({ data, location }) => {
    const post = data.post
    if (post.primary_author && post.primary_author.profile_image === null) {
        post.primary_author.profile_image = defaultImage
    }
    const shareUrl = post.url.replace(`https://muventa.com`, `https://sheyla.mx`)
    const recommended = data.recommended.edges

    return (
            <>
                <MetaData
                    data={data}
                    location={location}
                    type="article"
                />
                <Helmet>
                    <style type="text/css">{`${post.codeinjection_styles}`}</style>
                </Helmet>
                <Layout>
                    <div className="post">
                        <div className="home">
                            <div className="home_background parallax-window" style={{ backgroundImage: `url(${post.feature_image})` }}></div>
                            <div className="home_content">
                                {/* <div className="post_category trans_200"><a href="category.html" className="trans_200">sport</a></div> */}
                                <div className="post_title">{post.title}</div>
                            </div>
                        </div>

                        <div className="page_content">
                            <div className="container">
                                <div className="row row-lg-eq-height">
                                    <div className="col-lg-9">
                                        <div className="post_content">
                                            <div className="post_panel post_panel_top d-flex flex-row align-items-center justify-content-start">
                                                <div className="author_image">
                                                    <div><img src={post.primary_author.profile_image} alt={post.primary_author.name} /></div>
                                                </div>
                                                <div className="post_meta">
                                                    <a href="#" onClick={() => false}>{ post.primary_author.name }</a>
                                                    <span>{Moment(post.published_at).format(`MMM DD, YYYY [ a las ] h:mm a`)}</span>
                                                </div>
                                                <div className="post_share ml-sm-auto">
                                                    <span>Compartir</span>
                                                    <ul className="post_share_list">
                                                        <li className="post_share_item">
                                                            <FacebookShareButton url={shareUrl}>
                                                                <a href="#" onClick={() => false}><i className="fa fa-facebook" aria-hidden="true"></i></a>
                                                            </FacebookShareButton>
                                                        </li>
                                                        <li className="post_share_item">
                                                            <TwitterShareButton url={shareUrl}>
                                                                <a href="#" onClick={() => false}><i className="fa fa-twitter" aria-hidden="true"></i></a>
                                                            </TwitterShareButton>
                                                        </li>
                                                        <li className="post_share_item">
                                                            <WhatsappShareButton url={shareUrl}>
                                                                <a href="#" onClick={() => false}><i className="fa fa-whatsapp" aria-hidden="true"></i></a>
                                                            </WhatsappShareButton>
                                                        </li>
                                                        <li className="post_share_item">
                                                            <LinkedinShareButton url={shareUrl}>
                                                                <a href="#" onClick={() => false}><i className="fa fa-linkedin" aria-hidden="true"></i></a>
                                                            </LinkedinShareButton>
                                                        </li>
                                                        <li className="post_share_item">
                                                            <EmailShareButton url={shareUrl}>
                                                                <a href="#" onClick={() => false}><i className="fa fa-envelope" aria-hidden="true"></i></a>
                                                            </EmailShareButton>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <div className="panel-wrapper">
                                                {/* <a href="#show-more" className="show-more btn btn-read-more" id="show-more">Seguir Leyendo</a>  */}
                                                <div className="panel post_body" dangerouslySetInnerHTML={{ __html: post.html }}></div>
                                                {/* <div className="panel-fade"></div> */}
                                            </div>

                                            <div className="post_panel bottom_panel d-flex flex-row align-items-center justify-content-start">
                                                <div className="author_image">
                                                    <div><img src={post.primary_author.profile_image} alt={post.primary_author.name} /></div>
                                                </div>
                                                <div className="post_meta">
                                                    <a href="#" onClick={() => false}>{ post.primary_author.name }</a>
                                                    <span>{Moment(post.published_at).format(`MMM DD, YYYY [ a las ] h:mm a`)}</span>
                                                </div>
                                                <div className="post_share ml-sm-auto">
                                                    <span>Compartir</span>
                                                    <ul className="post_share_list">
                                                        <li className="post_share_item">
                                                            <FacebookShareButton url={shareUrl}>
                                                                <a href="#" onClick={() => false}><i className="fa fa-facebook" aria-hidden="true"></i></a>
                                                            </FacebookShareButton>
                                                        </li>
                                                        <li className="post_share_item">
                                                            <TwitterShareButton url={shareUrl}>
                                                                <a href="#" onClick={() => false}><i className="fa fa-twitter" aria-hidden="true"></i></a>
                                                            </TwitterShareButton>
                                                        </li>
                                                        <li className="post_share_item">
                                                            <WhatsappShareButton url={shareUrl}>
                                                                <a href="#" onClick={() => false}><i className="fa fa-whatsapp" aria-hidden="true"></i></a>
                                                            </WhatsappShareButton>
                                                        </li>
                                                        <li className="post_share_item">
                                                            <LinkedinShareButton url={shareUrl}>
                                                                <a href="#" onClick={() => false}><i className="fa fa-linkedin" aria-hidden="true"></i></a>
                                                            </LinkedinShareButton>
                                                        </li>
                                                        <li className="post_share_item">
                                                            <EmailShareButton url={shareUrl}>
                                                                <a href="#" onClick={() => false}><i className="fa fa-envelope" aria-hidden="true"></i></a>
                                                            </EmailShareButton>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-3">
                                        <div className="sidebar">
                                            <div className="sidebar_background"></div>
                                            <div className="sidebar_section">
                                                <div className="sidebar_title_container">
                                                    <div className="sidebar_title">Te Recomendamos</div>
                                                </div>
                                                <div className="sidebar_section_content">
                                                    <div className="sidebar_slider_container">
                                                        {recommended.map(({ node }) => (
                                                            <PostCardSide key={node.id} post={node} />
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Layout>
            </>
    )
}

Post.propTypes = {
    data: PropTypes.shape({
        post: PropTypes.shape({
            title: PropTypes.string.isRequired,
            html: PropTypes.string.isRequired,
            primary_author: PropTypes.object.isRequired,
            published_at: PropTypes.string,
            feature_image: PropTypes.string,
            url: PropTypes.string,
        }).isRequired,
        recommended: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
}

export default Post

export const postQuery = graphql`
    query($slug: String!) {
        post: ghostPost(slug: { eq: $slug }) {
            ...GhostPostFields
        },
        recommended: allGhostPost(
            sort: { order: DESC, fields: [published_at] },
            limit: 6,
            filter: {tags: {elemMatch: {slug: {eq: "recomendados"}}}}
        ) {
            edges {
                node {
                ...GhostPostFields
                }
            }
        }
    }
`
